<template>
  <div class="Client font">
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>

      <div
        class="content-wrapper container-xxl p-0 font"
        v-if="permissionCheck.viewAny == false"
      >
        <div class="p-2">
          <img style="width: 100%" src="/assets/image/accessDenide.png" alt="" />
        </div>
      </div>
      <div class="content-wrapper container-xxl p-0 font" v-else>
        <banner
          title="PROJECTS"
          :breadcrumb="[
            {
              label: 'Projects',
            },
            { label: 'Project List' },
          ]"
        >
        </banner>
        <div v-if="loading == true">
          <Spinner />
        </div>

        <div class="row font" style="margin-top: -45px">
          <div class="col-xl-12 col-md-12 col-sm-12 col-12">
            <div class="d-xl-none d-md-none d-block pt-1"></div>
            <div class="card custom-card">
              <div class="card-body">
                <div class="row">
                  <div class="col text font">
                    <h4><b style="color: #00364f; font-weight: bolder">Projects</b></h4>
                  </div>
                  <div class="col text-end">
                    <button
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#new-task-modal"
                      title="Filter"
                      class="btn text-white btn-sm"
                      style="background-color: #00364f"
                    >
                      <strong> <font-awesome-icon icon="filter" /></strong>
                    </button>
                  </div>
                  <div
                    class="col-xl-1 col-md-1 d-md-block d-xl-block d-none cus-p-1"
                    style="margin-top: -5px"
                  >
                    <select
                      style="height: 30px; padding: 5px 5px 5px 5px"
                      class="form-select"
                      @change="changePageNo"
                      aria-label="Default select example"
                    >
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                </div>

                <div class="row">
                  <div class="d-md-none d-xl-none d-block col-3 text font">
                    <select
                      style="height: 30px; padding: 5px 5px 5px 5px"
                      class="form-select"
                      @change="changePageNo"
                      aria-label="Default select example"
                    >
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                </div>

                <!-- start list area  -->
                <div class="row" style="margin-top:5px">
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="container-fluid table-scroll" style="margin-left: -5px">
                      <table class="table table-hover table-sm text font">
                        <tr class="text tr-head table-wrapper-scroll-y rounded-circle">
                          <th class="text-truncate">Project ID</th>
                          <th class="text-truncate">Date</th>
                          <th class="text-truncate">Client Name</th>
                          <th class="text-truncate">Retailer Name</th>
                          <th class="text-truncate">Business</th>
                          <th class="text-truncate">Service</th>
                          <th class="text-truncate">Phone</th>
                          <th class="text-truncate">Email</th>
                          <th class="text-truncate">Invoice</th>
                          <th class="text-truncate">Due Balance</th>

                          <th class="text-truncate">Progress</th>
                          <th class="text-truncate">Status</th>
                          <th class="text-truncate">Action</th>
                        </tr>
                        <tbody class="text-truncate">
                          <tr v-for="(project, index) in clientServices" :key="index">
                            <td class="text-truncate">
                              <span v-if="project"> {{ project.clientServiceNo }}</span>
                            </td>
                            <td class="text-truncate">
                              <span v-if="project">
                                {{ dateTime(project.date) }}
                              </span>
                            </td>
                            <td class="text-truncate">
                              <span v-if="project.client">
                                {{ project.client.fname }} {{ project.client.lname }}
                              </span>
                            </td>

                            <td class="text-truncate">
                              <span v-if="project.retailer">
                                {{ project.retailer.name }}
                              </span>
                            </td>
                            <td class="text-truncate">
                              <span v-if="project.business">
                                {{ project.business.name }}
                              </span>
                            </td>

                            <td class="text-truncate">{{ project.service.name }}</td>
                            <td class="text-truncate">
                              <span v-if="project.client">
                                {{ project.client.phone }}
                              </span>
                            </td>
                            <td class="text-truncate">
                              <span v-if="project.client">
                                {{ project.client.email }}
                              </span>
                            </td>
                            <td class="text-truncate">
                              <span v-if="project"
                                >Rs.
                                {{
                                  project.serviceCharge + project.serviceChargeGst
                                }}</span
                              >
                            </td>
                            <td class="text-truncate">
                              <span v-if="project">Rs.{{ getBallance(project) }}</span>
                            </td>
                            <td class="text-truncate">
                              <div class="progress" style="height: 7px">
                                <div
                                  :style="`background-color: #f21300; width: ${progressForProject(
                                    project.client_service_professional_step
                                  )}%`"
                                  class="progress-bar"
                                  role="progressbar"
                                  aria-valuemax="100"
                                ></div>
                              </div>
                            </td>
                            <td
                              v-if="project.client"
                              class="text-truncate"
                              style="text-transform: capitalize"
                            >
                              <p
                                v-if="project.status == 'assigned'"
                                class="btn btn-sm"
                                style="
                                  width: 60px;
                                  background-color: #00364f;
                                  color: white;
                                  box-shadow: 0px 0px 5px 0px gray;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding: 3px 3px 3px 3px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                <b>{{ project.status }}</b>
                              </p>
                              <p
                                v-if="project.status == 'completed'"
                                class="btn btn-sm"
                                style="
                                  width: 90px;
                                  background-color: #008000;
                                  color: white;
                                  box-shadow: 0px 0px 5px 0px gray;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding: 3px 3px 3px 3px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                <b>{{ project.status }}</b>
                              </p>

                              <p
                                v-if="project.status == 'pending'"
                                class="btn btn-sm"
                                style="
                                  width: 60px;
                                  background-color: #cdcdcd;
                                  color: white;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding: 3px 3px 3px 3px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                <b>New</b>
                              </p>
                              <p
                                v-if="project.status == 'processed'"
                                class="btn btn-sm"
                                style="
                                  width: 80px;
                                  background-color: #ffaa00;
                                  color: white;
                                  box-shadow: 0px 0px 5px 0px gray;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding: 3px 3px 3px 3px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                <b>{{ project.status }}</b>
                              </p>
                              <p
                                v-if="project.status == 'hold'"
                                class="btn btn-sm"
                                style="
                                  width: 60px;
                                  background-color: #8f8d8e;
                                  color: white;
                                  box-shadow: 0px 0px 5px 0px gray;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding: 3px 3px 3px 3px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                <b>{{ project.status }}</b>
                              </p>
                              <p
                                v-if="project.status == 'rejected'"
                                class="btn btn-sm"
                                style="
                                  width: 60px;
                                  background-color: #00364f;
                                  color: white;
                                  box-shadow: 0px 0px 5px 0px gray;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding: 3px 3px 3px 3px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                <b>{{ project.status }}</b>
                              </p>
                              <p
                                v-if="project.status == 'cancelled'"
                                class="btn btn-sm"
                                style="
                                  width: 80px;
                                  background-color: #f21000;
                                  color: white;
                                  box-shadow: 0px 0px 5px 0px gray;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding: 3px 3px 3px 3px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                <b>{{ project.status }}</b>
                              </p>
                              <p
                                v-if="project.status == 'upgrade'"
                                class="btn btn-sm"
                                style="
                                  width: 60px;
                                  background-color: #ee0def;
                                  color: white;
                                  box-shadow: 0px 0px 5px 0px gray;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding: 3px 3px 3px 3px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                <b>{{ project.status }}</b>
                              </p>
                            </td>
                            <td class="text-truncate text-end">
                              <!-- <button type="button" data-bs-toggle="modal" data-bs-target="#addon"
                                  class="btn btns btn-sm btn-success" @click="editModule(mod)"
                                  style="padding:5px 4.5px 5px 4.5px;">
                                  <font-awesome-icon icon="edit" />
                                </button> -->
                              <div class="btn-group btn-group-sm" role="group">
                                <!-- @click="loadProfessionals(project)" -->
                                <span v-if="permissionCheck.complete != false">
                                  <button
                                    @click="staffCahngeAssign(project)"
                                    v-if="project.status == 'processed'"
                                    class="btn btn-sm text-white"
                                    data-bs-toggle="modal"
                                    data-bs-target="#projectcompleatedModel"
                                    style="
                                      background-color: green;
                                      padding: 5px 4.5px 5px 4.5px;
                                    "
                                  >
                                    <i class="fa fa-check" aria-hidden="true"></i>
                                  </button>
                                </span>

                                <button
                                  v-if="permissionCheck.assing != false"
                                  @click="loadProfessionals(project)"
                                  class="btn btn-sm text-white"
                                  data-bs-toggle="modal"
                                  data-bs-target="#professional"
                                  style="
                                    background-color: blue;
                                    padding: 5px 4.5px 5px 4.5px;
                                  "
                                >
                                  <i class="fa fa-mail-reply-all" aria-hidden="true"></i>
                                </button>
                                <router-link
                                  v-if="permissionCheck.view != false"
                                  :to="`/staff/project-detailes/${project.id}`"
                                  class="btn btns btn-sm text-white"
                                  style="
                                    padding: 5px 4.5px 5px 4.5px;
                                    background-color: #00364f;
                                  "
                                >
                                  <font-awesome-icon icon="eye" />
                                </router-link>
                                <!-- <router-link
                                  to="#"
                                  class="btn btns btn-sm text-white"
                                  style="
                                    padding: 5px 4.5px 5px 4.5px;
                                    background-color: green;
                                  "
                                >
                                  <font-awesome-icon icon="edit" />
                                </router-link> -->
                                <!-- <router-link
                                  to="#"
                                  class="btn btns btn-sm text-white"
                                  style="
                                    padding: 5px 4.5px 5px 4.5px;
                                    background-color: red;
                                  "
                                >
                                  <font-awesome-icon icon="remove" />
                                </router-link> -->
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- end list area -->
              </div>
              <div class="card-footer">
                <div class="row">
                  <div class="col-md-4 col-12 text-md-start text-center cus-p-1">
                    <p class="mb-0">
                      Showing {{ pagination.from }} to {{ pagination.to }} of
                      {{ pagination.total }} entries
                    </p>
                  </div>
                  <div class="col-md-8 col-12 text-md-end text-center cus-p-1">
                    <ul class="pagination justify-content-center">
                      <li class="page-item" @click="loadClients(null, 'first')">
                        <a class="page-link" href="#" aria-label="Previous">
                          <span aria-hidden="true">&laquo;</span>
                        </a>
                      </li>
                      <li class="page-item" @click="loadClients(null, 'prev')">
                        <a class="page-link" href="#">Previous</a>
                      </li>
                      <li
                        class="page-item"
                        v-if="pagination.current_page > 3"
                        @click="loadClients(pagination.current_page - 3)"
                      >
                        <a class="page-link" href="#">{{
                          pagination.current_page - 3
                        }}</a>
                      </li>
                      <li
                        class="page-item"
                        v-if="pagination.current_page > 2"
                        @click="loadClients(pagination.current_page - 2)"
                      >
                        <a class="page-link" href="#">{{
                          pagination.current_page - 2
                        }}</a>
                      </li>
                      <li
                        class="page-item"
                        v-if="pagination.current_page > 1"
                        @click="loadClients(pagination.current_page - 1)"
                      >
                        <a class="page-link" href="#">{{
                          pagination.current_page - 1
                        }}</a>
                      </li>
                      <li
                        class="active page-item"
                        @click="loadClients(pagination.current_page)"
                      >
                        <a class="page-link" href="#">{{ pagination.current_page }}</a>
                      </li>
                      <li
                        class="page-item"
                        v-if="pagination.current_page < pagination.last_page - 4"
                        @click="loadClients(pagination.current_page + 1)"
                      >
                        <a class="page-link" href="#">{{
                          pagination.current_page + 1
                        }}</a>
                      </li>
                      <li
                        class="page-item"
                        v-if="pagination.current_page < pagination.last_page - 5"
                        @click="loadClients(pagination.current_page + 2)"
                      >
                        <a class="page-link" href="#">{{
                          pagination.current_page + 2
                        }}</a>
                      </li>
                      <li
                        class="page-item"
                        v-if="pagination.current_page < pagination.last_page - 6"
                        @click="loadClients(pagination.current_page + 3)"
                      >
                        <a class="page-link" href="#">{{
                          pagination.current_page + 3
                        }}</a>
                      </li>
                      <!-- <li v-if="pagination.current_page < (pagination.last_page - 4)" @click="loadClients( pagination.current_page + 4 )"><a href="#">{{pagination.current_page + 4}}</a></li> -->
                      <li class="page-item">
                        <a class="page-link" href="#">...</a>
                      </li>

                      <!-- <li v-if="pagination.current_page < (pagination.last_page - 4)" @click="loadClients( pagination.last_page - 4 )"><a href="#">{{pagination.last_page - 4}}</a></li> -->
                      <li
                        class="page-item"
                        v-if="pagination.current_page < pagination.last_page - 3"
                        @click="loadClients(pagination.last_page - 3)"
                      >
                        <a class="page-link" href="#">{{ pagination.last_page - 3 }}</a>
                      </li>
                      <li
                        class="page-item"
                        v-if="pagination.current_page < pagination.last_page - 2"
                        @click="loadClients(pagination.last_page - 2)"
                      >
                        <a class="page-link" href="#">{{ pagination.last_page - 2 }}</a>
                      </li>
                      <li
                        class="page-item"
                        v-if="pagination.current_page < pagination.last_page - 1"
                        @click="loadClients(pagination.last_page - 1)"
                      >
                        <a class="page-link" href="#">{{ pagination.last_page - 1 }}</a>
                      </li>

                      <li class="page-item" @click="loadClients(null, 'next')">
                        <a class="page-link" href="#">Next</a>
                      </li>
                      <li class="page-item" @click="loadClients(null, 'last')">
                        <a class="page-link" href="#">&raquo;</a>
                      </li>
                    </ul>
                  </div>

                  <!-- <div class="col-md-1 col-4 text-end">
      <select
                      style="height: 30px; padding: 5px 5px 5px 5px"
                      class="form-select"
                      @change="changePageNo"
                      aria-label="Default select example"
                    >
         
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
    </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Content-->

    <div class="sidenav-overlay"></div>
    <div class="drag-target"></div>
  </div>

  <!-- Modal -->
  <div
    class="modal fade"
    id="professional"
    tabindex="-1"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text" id="exampleModalLabel"><b>Assign Projects</b></h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="assignToProfessional">
            <div class="row font">
              <div
                class="col-xl-6 col-lg-6 col-md-6 col-12"
                style="padding: 5px 5px 5px 5px"
              >
                <div
                  class="container-fluid table-scroll border font"
                  style="border-radius: 8px"
                >
                  <nav>
                    <div
                      class="text-center"
                      style="
                        background-color: #00364f;
                        color: white;
                        padding: 5px 5px 5px 5px;
                      "
                    >
                      <strong>Internal Professional</strong>
                    </div>
                    <ul class="list-group list-group-flush">
                      <li
                        class="list-group-item"
                        v-for="(IntProfessional, index) in Internalprofessionals"
                        :key="index"
                      >
                      
                      <label class="form-check-label w-100" :for="`proInt1${IntProfessional.id}`">
                        <div
                          @click="loadClientSericeProfesssionalSteps(IntProfessional)"
                          class="row font"
                    
                        >
                          <div class="col-xl-2 col-lg-2 col-md-2 col-2">
                            <img
                              style="width: 40px; height: 40px"
                              :src="imageFind(IntProfessional)"
                              alt=""
                            />
                          </div>
                          <div
                          data-bs-toggle="collapse"
                          :href="`#Internalprofessionals${IntProfessional.id}`"
                            class="col-xl-9 col-lg-9 col-md-9 col-9"
                            style="line-height: 1; color: black"
                          >
                            <strong>{{ IntProfessional.name }}&nbsp;</strong
                            ><small> {{ IntProfessional.code }}</small
                            ><br />
                            <small style="font-size: 0.8vw">{{
                              IntProfessional.specialization
                            }}</small
                            ><br />
                            <strong style="font-size: 0.8vw"
                              >Pending:
                              {{ IntProfessional.clientServiceProfessionalPending }},
                              Completed:
                              {{
                                IntProfessional.clientServiceProfessionalCompleted
                              }}</strong
                            >
                          </div>
                          <div class="col-xl-1 col-lg-1 col-md-1 col-1 text-end">
                            <!-- :value="checkedOrNot(IntProfessional.id)" -->
                            <input
                              v-if="IntProfessional.clientServiceProfessional"
                              @click="valueAssign($event, IntProfessional.id, 'internal')"
                              :value="IntProfessional.id"
                              class="form-checkbox"
                              type="checkbox"
                              style="margin-right: -20px"
                              checked
                              :id="`proInt${IntProfessional.id}`"
                            />

                            <input
                              v-else
                              @click="valueAssign($event, IntProfessional.id, 'internal')"
                              :value="IntProfessional.id"
                              class="form-checkbox"
                              type="checkbox"
                              style="margin-right: -20px"
                              :id="`proInt${IntProfessional.id}`"
                            />
                          </div>
                        </div>
                      </label>
                        
                        <div class="row font">
                          <div class="col">
                            <div
                              class="collapse"
                              :id="`Internalprofessionals${IntProfessional.id}`"
                            >
                              <h4 class="font">
                                <strong style="color: black">TASKS</strong>
                              </h4>
                              <div
                                v-for="(task, index) in IntProfessional.tasks"
                                :key="index"
                              >
                              <span v-if="task.step_module_done">
                              <span
                                  v-if="task.step_module_done.professional_id  ==  IntProfessional.id"
                                >
                                  <input
                                    @click="
                                      valueAssignInSteps(
                                        $event,
                                        task,
                                        IntProfessional.id
                                      )
                                    "
                                    :id="`stepChek${IntProfessional.id}${index}`"
                                    checked
                                    style="color: red"
                                    type="checkbox"
                             
                                    :value="task.step.id"
                                  />
                                  <label style="margin-left: 5px">
                                    {{ task.step.title }}</label
                                  >
                                </span>
                                <span v-else>
                                  <input
                                    @click="
                                      valueAssignInSteps(
                                        $event,
                                        task,
                                        IntProfessional.id
                                      )
                                    "
                                    :id="`stepChek${IntProfessional.id}${index}`"
                                    style="color: red"
                                    type="checkbox"
                              
                                    :value="task.step.id"
                                  />
                                  <label style="margin-left: 5px">
                                    {{ task.step.title }}</label
                                  >
                                </span>
                            </span>
                            <span v-else>
                                  <input
                                    @click="
                                      valueAssignInSteps(
                                        $event,
                                        task,
                                        IntProfessional.id
                                      )
                                    "
                                    :id="`stepChek${IntProfessional.id}${index}`"
                                    style="color: red"
                                    type="checkbox"
                              
                                    :value="task.step.id"
                                  />
                                  <label style="margin-left: 5px">
                                    {{ task.step.title }}</label
                                  >
                                </span>

                      
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
              <div
                class="col-xl-6 col-lg-6 col-md-6 col-12"
                style="padding: 5px 5px 5px 5px"
              >
                <div
                  class="container-fluid table-scroll border font"
                  style="border-radius: 8px"
                >
                  <nav>
                    <div
                      class="text-center"
                      style="
                        background-color: #00364f;
                        color: white;
                        padding: 5px 5px 5px 5px;
                      "
                    >
                      <strong>External Professional</strong>
                    </div>
                    <ul class="list-group list-group-flush">
                      <li
                        class="list-group-item"
                        v-for="(extProfessional, index) in Externalprofessionals"
                        :key="index"
                      >

                      <label class="form-check-label w-100" :for="`proEx1${extProfessional.id}`">
                        <div
                         
                          class="row font"
                        
                        >
                          <div class="col-xl-2 col-lg-2 col-md-2 col-2">
                            <img
                              style="width: 40px; height: 40px"
                              :src="imageFind(extProfessional)"
                              alt=""
                            />
                          </div>
                          <div
                          data-bs-toggle="collapse"
                          :href="`#Internalprofessionals${extProfessional.id}`"
                            class="col-xl-9 col-lg-9 col-md-9 col-9"
                            style="line-height: 1; color: black"
                          >
                            <strong>{{ extProfessional.name }} &nbsp;</strong
                            ><small> {{ extProfessional.code }}</small
                            ><br />
                            <small style="font-size: 0.8vw">{{
                              extProfessional.specialization
                            }}</small
                            ><br />
                            <strong style="font-size: 0.8vw"
                              >Pending:
                              {{ extProfessional.clientServiceProfessionalPending }},
                              Completed:
                              {{
                                extProfessional.clientServiceProfessionalCompleted
                              }}</strong
                            >
                          </div>
                          <div class="col-xl-1 col-lg-1 col-md-1 col-1 text-end">
                            <input
                              v-if="extProfessional.clientServiceProfessional"
                              :value="extProfessional.id"
                              @click="valueAssign($event, extProfessional.id, 'external')"
                              class="form-checkbox"
                              type="checkbox"
                              style="margin-right: -20px"
                              checked
                              :id="`proEx${extProfessional.id}`"
                            />

                            <input
                            :id="`proEx${extProfessional.id}`"
                              v-else
                              :value="extProfessional.id"
                              @click="valueAssign($event, extProfessional.id, 'external')"
                              class="form-checkbox"
                              type="checkbox"
                              style="margin-right: -20px"
                            />
                          </div>
                        </div>
  </label>

                      
                        <div class="row font">
                          <div class="col">
                            <div
                              class="collapse"
                              :id="`Internalprofessionals${extProfessional.id}`"
                            >
                              <h4 class="font">
                                <strong style="color: black">TASKS</strong>
                              </h4>
                              <div
                                v-for="(task1, index) in extProfessional.tasks"
                                :key="index"
                              >
                            <span v-if="task1.step_module_done">
                              <span
                                  v-if="task1.step_module_done.professional_id  ==  extProfessional.id"
                                >
                                  <input
                                    @click="
                                      valueAssignInSteps(
                                        $event,
                                        task1,
                                        extProfessional.id
                                      )
                                    "
                                    :id="`stepChek${extProfessional.id}${index}`"
                                    checked
                                    style="color: red"
                                    type="checkbox"
                             
                                    :value="task1.step.id"
                                  />
                                  <label style="margin-left: 5px">
                                    {{ task1.step.title }}</label
                                  >
                                </span>
                                <span v-else>
                                  <input
                                    @click="
                                      valueAssignInSteps(
                                        $event,
                                        task1,
                                        extProfessional.id
                                      )
                                    "
                                    :id="`stepChek${extProfessional.id}${index}`"
                                    style="color: red"
                                    type="checkbox"
                              
                                    :value="task1.step.id"
                                  />
                                  <label style="margin-left: 5px">
                                    {{ task1.step.title }}</label
                                  >
                                </span>
                            </span>
                            <span v-else>
                                  <input
                                    @click="
                                      valueAssignInSteps(
                                        $event,
                                        task1,
                                        extProfessional.id
                                      )
                                    "
                                    :id="`stepChek${extProfessional.id}${index}`"
                                    style="color: red"
                                    type="checkbox"
                              
                                    :value="task1.step.id"
                                  />
                                  <label style="margin-left: 5px">
                                    {{ task1.step.title }}</label
                                  >
                                </span>
                               
                              
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>

            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-sm text-white"
                style="background-color: #f21300"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="submit"
                class="btn btn-sm text-white"
                style="background-color: #00364f"
              >
                <b>Confirm </b>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <!-- Right Sidebar starts -->
  <div class="modal modal-slide-in sidebar-todo-modal fade" id="new-task-modal">
    <div class="modal-dialog sidebar-sm">
      <div class="modal-content p-0">
        <form
          id="form-modal-todo"
          class="todo-modal needs-validation"
          novalidate
          onsubmit="return false"
        >
          <div
            class="modal-header align-items-center mb-1"
            style="background-color: #00364f"
          >
            <h5 class="modal-title text-white"><strong>Project Filter</strong></h5>
            <div
              class="todo-item-action d-flex align-items-center justify-content-between ms-auto text-white"
            >
              <span class="todo-item-favorite cursor-pointer me-75 text-white"
                ><i data-feather="star" class="font-medium-2"></i
              ></span>
              <i
                data-feather="x"
                class="cursor-pointer"
                data-bs-dismiss="modal"
                stroke-width="3"
                >X</i
              >
            </div>
          </div>
          <div class="modal-body flex-grow-1 pb-sm-0 pb-3">
            <ul class="list-group list-group-flush">
              <li class="list-group-item">
                <div class="form-check">
                  <input
                    @click="valueNullFilter()"
                    v-model="filterType"
                    value="clientServiceNo"
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="clientServiceNo"
                  />
                  <label class="form-check-label" for="clientServiceNo"> Code </label>
                </div>
              </li>
              <li class="list-group-item">
                <div class="form-check">
                  <input
                    @click="valueNullFilter()"
                    v-model="filterType"
                    value="date"
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="date"
                  />
                  <label class="form-check-label" for="date"> Date </label>
                </div>
              </li>
              <!--   <li class="list-group-item">
    <div class="form-check">
  <input v-model="filterType" value="dueDate" class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
  <label class="form-check-label" for="flexRadioDefault1">
  Due Date
  </label>
</div>
</li> -->
              <!-- <li class="list-group-item">
    <div class="form-check">
  <input v-model="filterType" value="completionDate" class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
  <label class="form-check-label" for="flexRadioDefault1">
  Completion Date
  </label>
</div>
  </li> -->
              <li class="list-group-item">
                <div class="form-check">
                  <input
                    @click="valueNullFilter()"
                    v-model="filterType"
                    value="client_id"
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="client_id"
                  />
                  <label class="form-check-label" for="client_id"> Client PanNo </label>
                </div>
              </li>

              <li class="list-group-item">
                <div class="form-check">
                  <input
                    @click="valueNullFilter()"
                    v-model="filterType"
                    value="status"
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="status"
                  />
                  <label class="form-check-label" for="status"> Status </label>
                </div>
              </li>

              <li class="list-group-item">
                <div class="form-check">
                  <input
                    @click="valueNullFilter()"
                    v-model="filterType"
                    value="service_id"
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="service_id"
                  />
                  <label class="form-check-label" for="service_id"> Service </label>
                </div>
              </li>
              <li class="list-group-item">
                <div class="form-check">
                  <input
                    @click="valueNullFilter()"
                    v-model="filterType"
                    value="all"
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="all"
                  />
                  <label class="form-check-label" for="all"> All </label>
                </div>
              </li>
            </ul>

            <div class="pt-1 pb-1">
              <!-- <label for="exampleFormControlInput1" class="form-label">Email address</label> -->
              <input
                type="date"
                v-if="
                  filterType == 'date' ||
                  filterType == 'dueDate' ||
                  filterType == 'completionDate'
                "
                class="form-control datepicker"
                v-model="filterValue"
                id="exampleFormControlInput1"
                placeholder="Enter Value"
              />
              <input
                type="text"
                v-if="filterType == 'clientServiceNo'"
                class="form-control"
                v-model="filterValue"
                id="exampleFormControlInput1"
                placeholder="Enter Value"
              />
              <input
                type="text"
                v-if="filterType == 'client_id'"
                @change="findRecordeByPanId"
                class="form-control"
                v-model="filterValue"
                id="exampleFormControlInput1"
                placeholder="Enter Value"
              />

              <select
                v-model="filterValue"
                v-if="filterType == 'status'"
                class="form-select"
                aria-label="Default select example"
              >
                <option selected disabled>Please Select...</option>
                <option value="pending">New</option>
                <option value="assigned">Assign</option>
                <option value="completed">Complete</option>
                <option value="cancelled">Cancelled</option>
                <option value="accepted">Accepted</option>
                <option value="rejected">Rejected</option>
                <option value="upgrade">Upgrade</option>
              </select>

              <select
                v-model="filterValue"
                v-if="filterType == 'service_id'"
                class="form-select"
                aria-label="Default select example"
              >
                <option selected disabled>Please Select...</option>
                <option
                  v-for="(service, index) in services"
                  :key="index"
                  :value="service.id"
                >
                  {{ service.name }}
                </option>
              </select>
            </div>

            <button
              v-if="filterType != 'client_id'"
              @click="loadClients()"
              type="button"
              class="btn btn-success btn-sm"
            >
              Success
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- Right Sidebar ends -->
</template>

//
<script>
import Permissions from "../../../mixins/permission";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import moment from "moment";
import Banner from "../../../components/staff/comman/Banner.vue";
import Spinner from "../../../components/staff/comman/Spinner.vue";
// import Form from "vform";
import jQuery from "jquery";
let $ = jQuery;
var _ = require("lodash");
export default {
  mixins: [Permissions],
  name: "Projects",
  components: {
    Banner,
    Spinner,
  },
  data() {
    return {
      services: [],
      filterValue: "",
      filterType: "",
      pagination: {
        current_page: 0,
        from: 0,
        to: 0,
        total: 0,
        last_page: 0,
        per_page: 0,
        first_page_url: null,
        last_page_url: null,
        next_page_url: null,
        prev_page_url: null,
        path: null,
      },
      per_page: 10,
      clientService_id: "",
      pre: true,
      proServices: [],
      proSteps: [],
      form: {
        client_service_id: null,
        service_id: null,
        professionals: [],
      },
      stsFromChange: {
        client_service_id: null,
        service_id: null,
        status: "",
        remark: "",
      },
      selectedpro: [],
      loading: false,
      extProf: [],
      Externalprofessionals: [],
      Internalprofessionals: [],
      clientServiceProfessionals: [],

      clientServices: [],
      statusQueryArray: [],
      tasks: [],
      steps: [],
      assign_form: {
        client_service_id: null,
        multipleAsignIdProfessional: [],
        multipleAsignIdStaff: [],
      },
      client_service_id: null,
      permissionCheck: {
        view: "",
        viewAny: true,
        complete: "",
        assing: "",
      },
    };
  },
  methods: {
    valueNullFilter() {
      this.filterValue = "";
    },
    getBallance(client_service) {
      var bal = 0;
      var payments = client_service.client_payment;

      if (client_service.client_payment) {
        console.log("***************");
        console.log(payments);
        for (var i = 0; i < payments.length; i++) {
          bal += payments[i].amt;
        }
        bal = client_service.serviceCharge + client_service.serviceChargeGst - bal;
        return bal;
      } else {
        return client_service.serviceCharge + client_service.serviceChargeGst;
      }
    },
    servicesLoad() {
      this.$axios
        .post(
          `allservice`,
          {},
          {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          }
        )
        .then((res) => {
          this.services = res.data.data;
          console.log(res.data.data);
        });
    },
    permissionsCheker() {
      this.permissionCheck.view = this.loadPermissions("clientservice-view");
      // this.permissionCheck.viewAny = this.loadPermissions("clientservice-view-any");

      this.permissionCheck.assing = this.loadPermissions("clientservice-assign");
      this.permissionCheck.complete = this.loadPermissions("clientservice-complete");
    },
    changePageNo(e) {
      this.per_page = e.target.value;
      this.loadClients();
    },
    progressForProject(tasks) {
      var complete = 0;
      for (var i = 0; i < tasks.length; i++) {
        if (tasks[i].status == "complete") {
          complete += 1;
        }
      }
      var n = 100 / tasks.length;
      return n * complete;
    },

    dateTime(value) {
      return moment(value).format("DD-MM-YYYY");
    },
    imageFind(professional) {
      var data;
      if (professional.photo != null) {
        data =
          this.$store.state.imgUrl +
          "/professional/" +
          professional.id +
          "/thumbs/" +
          professional.photo;
        return data;
      } else {
        data = this.$store.state.placeholderImg;
        return data;
      }
    },
    pushStatusvalueInArray(event, value) {
      if (event.target.checked == true) {
        this.statusQueryArray.push(value);
        this.loadClients();
      } else if (event.target.checked == false) {
        this.statusQueryArray.splice(this.statusQueryArray.indexOf(value), 1);
        this.loadClients();
      }
    },
    valueAssignInSteps(event, task, professional_id) {
      console.log(professional_id);
      if (event.target.checked == true) {
        for (var i = 0; i < this.form.professionals.length; i++) {
          if (this.form.professionals) {
            if (professional_id == this.form.professionals[i].professional_id) {
              this.form.professionals[i].steps.push(task.step_id);
            }
          }
        }
      } else if (event.target.checked == false) {
        for (i = 0; i < this.form.professionals.length; i++) {
          if (this.form.professionals) {
            if (professional_id === this.form.professionals[i].professional_id) {
              this.form.professionals[i].steps.splice(
                this.form.professionals[i].steps.indexOf(task.step_id),
                1
              );
            }
          }
        }
      }

      console.log(this.form);
    },
    staffCahngeAssign(project) {
      // console.log(project);
      // this.stsFromChange.client_service_id = project.id;
      // this.stsFromChange.service_id = project.service.id;
      var form = {};
      form.client_service_id = project.id;
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be Complete it",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#00364f",
          cancelButtonColor: "#f21000",
          confirmButtonText: "Yes, Complete it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.loading = true;
            this.$axios
              .post(`staff/completeclientservice`, form, {
                headers: {
                  Authorization: "Bearer " + localStorage.accessToken,
                },
              })
              .then((res) => {
                res;

                toast.success("Service Compleated  Succesfully", {
                  autoClose: 1000,
                });
                this.loadClients();
              })
              .finally(() => (this.loading = false))
              .catch((error) => {
                this.submitStatus = "true";
                this.$swal.fire({
                  position: "top-center",
                  icon: "error",
                  title: "<small>" + error.response.data.error + "</small>",
                  showConfirmButton: true,
                  confirmButtonColor: "#00364f",
                });
              });
          }
        });
    },
    valueAssign(event, id, data) {
      var value = id;

      if (event.target.checked == true) {
        if (data == "internal") {
          this.form.professionals.push({
            professional_id: value,
            professionalCategory: "internal",
            steps: [],
          });
        }
        if (data == "external") {
          this.form.professionals.push({
            professional_id: value,
            professionalCategory: "external",
            steps: [],
          });
        }

        // this.loadClients()
      } else if (event.target.checked == false) {
        if (data == "internal") {
          this.form.professionals.splice(
            this.form.professionals.indexOf({
              professional_id: value,
              professionalCategory: "internal",
            }),
            1
          );
        }
        if (data == "external") {
          this.form.professionals.splice(
            this.form.professionals.indexOf({
              professional_id: value,
              professionalCategory: "external",
            }),
            1
          );
        }

        // this.loadClients()
      }
    },
    assignToProfessional() {
      //   this.loading = true;
      this.$axios
        .post("staff/clientserviceprofessional", this.form, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          res;
          toast.success("Assign Succesfully", {
            autoClose: 1000,
          });

          $("#professional").modal("hide");
          // this.$swal.fire({
          //   position: "top-end",
          //   icon: "success",
          //   title: "Assign Succesfully ",
          //   showConfirmButton: false,
          //   timer: 1500,
          // });
          this.loadClients();
        })

        .catch((error) => {
          console.log(error);
          // toast.danger("Assign Succesfully", {
          //   autoClose: 1000,
          // });
          this.$swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.response.data.error.professionals,

            position: "top-center",

            showConfirmButton: true,
            // timer: 1500,
          });
        });
      // .finally(() => (this.loading = false));
    },
    loadProfessionals(project) {
      this.tasks = [];

      if (project.service) {
        console.log("+++++++++++++++++++++++vvvvvvv+++++++++++++++++++++++++++++++++++");
        console.log(this.clientServices.service);
        this.tasks = project.service.service_steps;
      }

      this.clientServiceProfessionals = project.client_service_professional;
      var client_service_professional_step = project.client_service_professional_step;

      this.Internalprofessionals = [];
      this.Externalprofessionals = [];
      // this.proSteps = [];
      // this.proServices = [];

      this.client_service_id = project.id;

      this.$axios
        .get(`staff/professional?professionalCategory=internal&all=all`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          this.Internalprofessionals = res.data.data;

          

          for (var i = 0; i < this.Internalprofessionals.length; i++) {
            this.Internalprofessionals[i].tasks = this.tasks;
          }

          var serModule = this.Internalprofessionals;

          console.log("------------------------");
          _.forEach(this.clientServiceProfessionals, function (value) {
            if (
              _.find(serModule, function (o) {
                return o.id == value.professional_id;
              })
            ) {
              var index = _.findIndex(serModule, function (o) {
                return o.id == value.professional_id;
              });
              serModule[index].clientServiceProfessional = value;

              // for step

              var serModuleStep = serModule[index].tasks;

              console.log("-----------555555555-------------");

              _.forEach(client_service_professional_step, function (valueObj) {
                if (value.professional_id == valueObj.professional_id) {
                  if (
                    _.find(serModuleStep, function (obj) {
                      return obj.step_id == valueObj.step_id;
                    })
                  ) {
                    var index123 = _.findIndex(serModuleStep, function (obj) {
                      return obj.step_id == valueObj.step_id;
                    });

                
                    if (
                      value.professional_id == valueObj.professional_id &&
                      valueObj.step_id == serModuleStep[index123].step_id
                    ) {
                      serModuleStep[index123].step_module_done = valueObj;
                    }
                  }

                  console.log(serModuleStep);

                  // end of loop
                }
              });
              serModule[index].tasks = serModuleStep;

              // for end  stap
            }
          });
          this.Internalprofessionals = serModule;



        });

      this.$axios
        .get(`staff/professional?professionalCategory=external&all=all`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          this.Externalprofessionals = res.data.data;

          for (var j = 0; j < this.Externalprofessionals.length; j++) {
            this.Externalprofessionals[j].tasks = this.tasks;
          }

          var serModule1 = this.Externalprofessionals;

          console.log("------------------------");
          _.forEach(this.clientServiceProfessionals, function (value) {
            if (
              _.find(serModule1, function (o) {
                return o.id == value.professional_id;
              })
            ) {
              var index = _.findIndex(serModule1, function (o) {
                return o.id == value.professional_id;
              });
              serModule1[index].clientServiceProfessional = value;

              // for step

              var serModuleStep1 = serModule1[index].tasks;

              console.log("-----------555555555-------------");

              _.forEach(client_service_professional_step, function (value123) {
                if (value.professional_id == value123.professional_id) {
                  if (
                    _.find(serModuleStep1, function (obj) {
                      return obj.step_id == value123.step_id;
                    })
                  ) {
                    var index123 = _.findIndex(serModuleStep1, function (obj) {
                      return obj.step_id == value123.step_id;
                    });

                
                    if (
                      value.professional_id == value123.professional_id &&
                      value123.step_id == serModuleStep1[index123].step_id
                    ) {
                      serModuleStep1[index123].step_module_done = value123;
                    }
                  }

                  console.log(serModuleStep1);

                  // end of loop
                }
              });
              serModule1[index].tasks = serModuleStep1;

              // for end  stap
            }
          });
          this.Externalprofessionals = serModule1;
        });

      this.form.service_id = project.service.id;
      this.form.client_service_id = project.id;

      // this.loadTasks();
      // .finally(() => (this.loading = false));

      // this.loadClientSericeProfesssional();
    },
    loadClientSericeProfesssional() {
      this.$axios
        .get(
          `staff/clientserviceprofessional?client_service_id=${this.client_service_id}`,
          {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          }
        )
        .then((res) => {
          res;

          this.clientServiceProfessionals = res.data.data.data;
          // var  clientserviceprofessional = res.data.data.data;
          // ---------------------------------------------------
          var serModule = this.Internalprofessionals;

          var serModule1 = this.Externalprofessionals;

          console.log("------------------------");
          _.forEach(res.data.data.data, function (value) {
            if (
              _.find(serModule, function (o) {
                return o.id == value.professional_id;
              })
            ) {
              var index = _.findIndex(serModule, function (o) {
                return o.id == value.professional_id;
              });
              serModule[index].clientServiceProfessional = value;
            }
          });
          this.Internalprofessionals = serModule;

          _.forEach(res.data.data.data, function (value) {
            if (
              _.find(serModule1, function (o) {
                return o.id == value.professional_id;
              })
            ) {
              var index = _.findIndex(serModule1, function (o) {
                return o.id == value.professional_id;
              });
              serModule1[index].clientServiceProfessional = value;
            }
          });
          this.Externalprofessionals = serModule1;
        });
    },
    loadClients(page = null, pg = null) {
      console.log(page);
      console.log(pg);

      var pageUrl = "";
      if (this.pagination.last_page_url && pg == "last") {
        pageUrl = pageUrl + this.pagination.last_page_url;
      } else if (this.pagination.first_page_url && pg == "first") {
        pageUrl = pageUrl + this.pagination.first_page_url;
      } else if (this.pagination.prev_page_url && pg == "prev") {
        pageUrl = pageUrl + this.pagination.prev_page_url;
      } else if (this.pagination.next_page_url && pg == "next") {
        pageUrl = pageUrl + this.pagination.next_page_url;
      }

      if (pg == null) {
        if (this.filterType == "all") {
          this.filterValue = "";
          this.filterType = "";
        }

        if (this.filterValue != null && this.filterType != null) {
          pageUrl += `staff/clientservice?per_page=${this.per_page}&${this.filterType}=${this.filterValue}`;
        } else {
          pageUrl += `staff/clientservice?per_page=${this.per_page}`;
        }
      } else {
        pageUrl += `&per_page=${this.pagination.per_page}`;
      }
      if (this.table_search) {
        pageUrl += `&searchTerm=${this.table_search}`;
      }
      if (page != null) {
        pageUrl += `&page=${page}`;
      }

      this.loading = true;
      this.$axios
        .get(`${pageUrl}`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          this.clientServices = res.data.data.data;

          this.pagination = res.data.data;
          this.filterValue = "";
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          if( error.response.data.error == 'Sorry! You do not have permission to access.'){
            this.permissionCheck.viewAny = false
          }
            this.$swal.fire({
              position: "top-center",
              icon: "error",
              title: "<small>" + error.response.data.error + "</small>",
              showConfirmButton: true,
              confirmButtonColor: "#00364f",
            });
          });
    },
    stsChangeStafSave() {
      this.loading = true;
      var q = "";
      if (this.stsFromChange.status == "rejected") {
        q = "clientserviceprofessional/reject";
      } else {
        q = "completeclientservice";
      }

      this.$axios
        .post(`staff/${q}`, this.stsFromChange, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          res;
          // this.clientServices = res.data.data.data;
          toast.success("Status Changed Succesfully", {
            autoClose: 1000,
          });
          $("#projectcompleatedModel").modal("hide");
          this.loadClients();
        })
        .finally(() => (this.loading = false));
    },
    findRecordeByPanId(e) {
      this.$axios
        .get(`staff/client?pan=${e.target.value}`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          this.filterValue = "0";
          if (res.data.data.data[0]) {
            this.filterValue = res.data.data.data[0].id;
          }
          this.loadClients();
        });
    },
    loadClientSericeProfesssionalSteps(professional) {
      console.log(professional);
      this.$axios
        .get(
          `staff/clientserviceprofessionalstep?client_service_id=${this.client_service_id}&professional_id=${professional.id}`,
          {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          }
        )

        .then((res) => {
          res;
          // console.log(res.data.data.data);
          // var result = res.data.data.data;
          // for (var i = 0; i < result.length; i++) {
          //   this.proSteps.push(result[i].step_id);
          // }

          console.log(res.data.data.data);

          var clientServiceProfessionalsetps = res.data.data.data;

          console.log(this.tasks);

          // ---------------------------------------------------
          var serModule = this.tasks;

          console.log("------------------------");
          _.forEach(clientServiceProfessionalsetps, function (value) {
            if (
              _.find(serModule, function (o) {
                return o.id == value.step_id;
              })
            ) {
              var index = _.findIndex(serModule, function (o) {
                return o.id == value.step_id;
              });
              serModule[index].clientServiceProfessionalsetps = value;
            }
          });
          this.tasks = serModule;

          console.log(this.tasks);
        });
    },
    loadPageTitle() {
      document.title = this.$store.state.staffPageTitles.clientsService;
    },
  },
  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    } else {
      this.loadClients();
      this.permissionsCheker();
      this.servicesLoad();
      this.loadPageTitle();
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");
/* 
.custom-card {
  width: 95%;
  background-color: white;
  border-radius: 20px;
  box-shadow: 2px 2px 8px gray;
  line-height: 10%;
  margin-left: 13px;
}

.h4-heading {
  color: red;
  font-size: 1.2vw;
}

.font-heading {
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  color: rgb(216, 209, 209);
}

.text {
  font-family: "Poppins", sans-serif;
  color: #00364f;
}

.font {
  font-family: "Poppins", sans-serif;
}

.btns {
  background-color: #00364f;
  font-size: 12px;
  padding: 8px 8px 8px 8px;
}

.radius {
  border-radius: 23px;
}

.form-check-input:checked {
  background-color: #fefeff;
  border-color: #f21003;
  border-width: 3px;
}

.tr-head {
  font-size: 11pt;
  margin-left: 25px;
  border-radius: 26px;
  background-color: #cdcdcd;
}

th {
  width: 200px;
}

.table-scroll {
  overflow-y: auto;
}

.form-select {
  border: 1px solid #00364f;
}

.form-checkbox {
  width: 25px;
  margin-top: 5px;
}

nav ul {
  height: 300px;
  width: 100%;
}

nav ul {
  overflow: hidden;
  overflow-y: scroll;
}

.card .card {
  box-shadow: rgb(0 0 0 / 20%) 0px 4px 8px 0px, rgb(0 0 0 / 19%) 0px 6px 20px 0px !important;
}

div.scrollmenu {
  overflow: auto;
  white-space: nowrap;
}

div.scrollmenu a {
  display: inline-block;
  color: white;
  font-family: "Poppins", sans-serif;
  color: #00364f;
  text-decoration: none;
}

div.scrollmenu a .card {
  height: 50%;
  width: 40%;
}

.come-from-right .modal-dialog {
  position: fixed;
  top: 0px;
  right: 0px;
  margin: auto;
  width: 300px;
  height: 100%;
  transform: translate3d(0%, 0, 0);
  transition: opacity 0.3s linear, left 0.3s ease-out;
}

.come-from-right .modal-content {
  height: 100%;
  overflow-y: auto;
  border-radius: 0px;
}

.come-from-right .modal-body {
  padding: 15px 15px 80px;
} */
</style>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

.table-scroll {
  overflow-y: auto;
}

.form-select {
  border: 1px solid #00364f;
}

.form-checkbox {
  width: 25px;
  margin-top: 5px;
}

nav ul {
  height: 300px;
  width: 100%;
}

nav ul {
  overflow: hidden;
  overflow-y: scroll;
}

div.scrollmenu {
  overflow: auto;
  white-space: nowrap;
}

div.scrollmenu a {
  display: inline-block;
  color: white;
  font-family: "Poppins", sans-serif;
  color: #00364f;
  text-decoration: none;
}

div.scrollmenu a .card {
  height: 50%;
  width: 40%;
}

.come-from-right .modal-dialog {
  position: fixed;
  top: 0px;
  right: 0px;
  margin: auto;
  width: 300px;
  height: 100%;
  transform: translate3d(0%, 0, 0);
  transition: opacity 0.3s linear, left 0.3s ease-out;
}

.come-from-right .modal-content {
  height: 100%;
  overflow-y: auto;
  border-radius: 0px;
}

.come-from-right .modal-body {
  padding: 15px 15px 80px;
}
.custom-card {
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(206, 205, 205, 0.555);
  background-color: white;
}
@media screen and (max-width: 767px) {
  .mt-top-numbers {
    margin-top: -26px;
  }
}

.h1-heading {
  color: red;
  font-size: 3vw;
}

.font-heading {
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  color: rgb(216, 209, 209);
}

.text {
  font-family: "Poppins", sans-serif;
  color: #00364f;
}

.btns {
  display: inline-flex;
}

.tr-head {
  font-size: 11pt;
  margin-left: 25px;
  border-radius: 26px;
  background-color: #cdcdcd;
}

.table-scroll {
  overflow-y: auto;
}
.page-item.active .page-link {
  z-index: 3;
  border-radius: 5rem;
  background-color: #00364f;
  color: #fff !important;
  font-weight: 600;
}
h4[data-v-69e5a8da] {
  color: #fff;
  font-family: poppins, Sans-serif;
  font-weight: 600;
  font-size: 25;
}

.form-check-input:checked {
  background-color: #ffffff;
  border-color: #f21000;
  border-width: 5px;
}
.form-check-input:not(:disabled):checked {
  box-shadow: 0 2px 4px 0 rgb(225 0 0 / 28%);
}

.cus-p-1 {
  padding: 5px 5px 5px 5px;
}

@media only screen and (min-width: 600px) {
  .justify-content-center {
    justify-content: end !important;
  }
}

.card-footer {
  padding: 0px 10px 0px 10px;
}
</style>
